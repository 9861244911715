import { useStaticQuery, graphql } from "gatsby";

export const useHeaderData = () => {

    const data = useStaticQuery(graphql`
    query header {
        prismicHeaderdata {
            data {
              button_data {
                url
                name
              }
              menudata {
                path
                title
              }
            }
          }
      }
    `)

    const headerData = data.prismicHeaderdata.data
      return headerData
}
