import React, { useState } from "react";
import "../../style/styles.scss"
import BodyClassName from "react-body-classname";
import { useHeaderData } from "../StaticDataHooks/useHeaderData";
import NavContainer from "./NavContainer";

const Header = () => {

    const content = useHeaderData()
    const menuLinks = content.menudata
    const buttonsData = content.button_data

    const [hambugerActive, setHambugerActiveState] = useState(false)

    const hamburgerHandler = () => {
      setHambugerActiveState(!hambugerActive)
    }

    let hambugerClsName = "hamburger my-auto m-4 "
    let navMenuClsName = "nav-items desktop"
    let bodyClsName = ""
    if (hambugerActive) {
        hambugerClsName += "active"
        navMenuClsName += " active"
        bodyClsName += "bk-color-white"
    }

    return (
        <BodyClassName className={bodyClsName}>
            <header className="header fixed-top">
                <div className="layout-container">
                    <NavContainer 
                        menuLinks={menuLinks}
                        buttonsData={buttonsData}
                        navMenuClsName={navMenuClsName}
                        hambugerClsName={hambugerClsName}
                        hamburgerHandler={hamburgerHandler}
                    />
                </div>
            </header>
        </BodyClassName>
    )
}


export default Header