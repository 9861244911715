import React from "react";
import { Link } from "gatsby";

const NavLinks = ({ menuLinks }) => {

    return (
        menuLinks.map(item => (
            <li className="nav-item">
                <Link className="nav-link menuLink" to={item.path}>
                    {item.title}
                </Link>
            </li>
        ))
    )
}

export default NavLinks