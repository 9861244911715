import React from "react";
import { Image } from "../Image/Image"
import { IconsData } from "./IconsData";

const Icons = () => {

    return (
        IconsData.map(item => (
            <a href={item.path}>
                <Image src={item.icon} alt={item.alt} loading={"eager"} fadeIn={false}/>
            </a>
        ))
    )
}


const SocialIcons = () => {

    return (
        <div className="links">
            <div className="social-links">
                <Icons />
            </div>
        </div>
    )
}

export default SocialIcons