import React from "react";
import SocialIcons from "./SocialIcons"
import { Link } from "gatsby";

const Footer = () => {
    
    return (
        <div className="footer mt-2">
            <div className="layout-container">
                <div className="foootr-navbar">
                    <div className="row">

                    </div>
                </div>
                <div className="bottom-bar">
                    <Link to="/">
                    </Link>
                    <SocialIcons />
                </div>
            </div>
        </div>
    )
}

export default Footer