import React from "react";
import ActionBtns from "./ActionBtns";
import Hamburger from "./Hamburger";
import LogoContainer from "./LogoContainer";
import NavLinks from "./NavLinks";

const NavContainer = ({ menuLinks, buttonsData, navMenuClsName, hambugerClsName, hamburgerHandler }) => {


    return (
        <nav className="header-nav">
            <LogoContainer />
            <Hamburger hambugerClsName={hambugerClsName} hamburgerHandler={hamburgerHandler}/>
            <ul className={navMenuClsName}>
                <NavLinks menuLinks={menuLinks} />
                <ActionBtns buttonsData={buttonsData}/>
            </ul>
        </nav>
    )
}


export default NavContainer