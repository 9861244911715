import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const PageLayout = ({ children }) => {


    return (

        <>  
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    )
}


export default PageLayout