import React from "react";



const ActionBtns = ({ buttonsData }) => {


    return (
        <li className="actions-btns">
            <a className="btn btn-second" href={buttonsData[0].url}>
                {buttonsData[0].name}
            </a>
            <a className="btn btn-primary" href={buttonsData[1].url}>
                {buttonsData[1].name}
            </a>
        </li>

    )
}


export default ActionBtns