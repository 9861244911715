import React from "react";

const SectionLayout = ({ sectionName, children, id, containerName }) => {


    return (

        <section className={"section " + sectionName} id={id}>
            <div className={"layout-container " + containerName}>
                {children}
            </div>
        </section>
    )
}

export default SectionLayout