import { Twitter,
    Discord,
    Telegram
} from "../../utils/ImgLoader"

export const IconsData = [
    {
       icon: Twitter,
       path: 'https://twitter.com/altradyapp',
       alt: 'Twitter Link'
    },
    {
       icon: Discord,
       path: 'https://discord.gg/s5DuEhh',
       alt: 'Discord Link'
    },
    {
       icon: Telegram,
       path: 'https://t.me/altrady',
       alt: 'Telegram Link'
    },
]