import React from "react";
import { Logo } from "../../utils/ImgLoader";
import { Image } from "../Image/Image";
import { Link } from "gatsby";


const LogoContainer = () => {

    return(
        <Link to="/">
            <Image 
                src={Logo}
                loading={"eager"} 
                fadeIn={false}
                width={230}
            />
        </Link>
    )
}

export default LogoContainer