import React from "react";

const Hamburger = ({ hamburgerHandler, hambugerClsName }) => {

    return (

        <div
            className={hambugerClsName}
            onClick={hamburgerHandler}
            onKeyDown={hamburgerHandler}
            role="button"
            tabIndex="0"
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}


export default Hamburger